
























import { Component, Vue, Emit, Prop } from 'vue-property-decorator'

@Component({
  name: 'ContentHeader',
  components: {
    ArrowLeftIcon: () =>
      import('@/components/UI/icons/LoginBadIcons/IconArrowLeftNew.vue'),
  },
})
export default class ContentHeader extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  readonly label!: string

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly showBackIcon!: boolean

  @Emit('backClicked')
  onClickBackIcon () {}
}

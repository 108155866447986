

































































































import { Team } from '@tada-team/tdproto-ts'
import { Component, Vue } from 'vue-property-decorator'
import {
  loginStore,
  teamsStore,
  uiSettingsStore,
} from '@/store'
import { loginLogger } from '@/loggers'
import { biCheck2 } from '@quasar/extras/bootstrap-icons'
import ContentHeader from './components/ContentHeader.vue'
import { transformEntityName } from '@/utils'

@Component({
  name: 'TheInvitationsView',
  components: {
    ContentHeader,
    TheZeroInvitations: () => import('./components/TheZeroInvitations.vue'),
  },
})
export default class TheInvitationsView extends Vue {
  checkIcon!: string

  loading = false

  current = 1 // index of current showing team

  cookieInviteTeam: Team | null = null
  inviteToken = '' // from cookie "invitation_token"

  /**
   * Get cookie "invitation_token" and ask for info about it if exists
   */
  async created () {
    this.checkIcon = biCheck2

    const token = this.$q.cookies.get('invitation_token')
    if (token) {
      this.inviteToken = token
      const info: { team: Team } = await loginStore.actions.getInvitationInfo(
        this.inviteToken,
      )
      this.cookieInviteTeam = info.team
    }
  }

  /**
   * Get invitations
   * Mixed of all teams where needConfirmation === true and invitation from cookie (based on invite link)
   */
  get invitations () {
    const arr = teamsStore.getters.teams.filter((team: Team) => team.needConfirmation)
    if (this.cookieInviteTeam) return [...arr, this.cookieInviteTeam]
    return arr
  }

  /**
   * Get current active team by index
   * custom types for Vetur experimental typing in templates
   */
  get team (): Team & { largeIcon?: any; icon?: any } {
    return this.invitations[this.current - 1]
  }

  /**
   * Accepted is fixtion and reset by page reloading because of backend api!!!
   * Accept === add uid to store array only, but delete is real!
   * @see acceptedInvitesUids in store
   */
  get isInviteAccepted () {
    return loginStore.state.acceptedInvitesUids.includes(this.team?.uid ?? '')
  }

  /**
   * Get label type for team by uid
   */
  get label () {
    return this.isInviteAccepted
      ? this.$t('auth.newOnboarding.acceptedInviteBody')
      : this.$t('auth.newOnboarding.defaultInviteBody', {
        teamName: transformEntityName(this.team.name),
      })
  }

  /**
   * Get team icon url by uid
   */
  get icon (): string {
    return teamsStore.getters.teamIconURL(this.team.uid, true) ?? ''
  }

  /**
   * If it's cookie invitation - dispatch join by invite
   * Then add to array of accepted invites UID of team
   * If it's only ONE invites on screen - go to team instantly
   * @todo change fixtion of accepting to real method on backend
   */
  async acceptInvite () {
    window.goal('onboardingAction', { onboarding: 'Приглашения — «Принять»' })
    const uid = this.team.uid
    if (uid === this.cookieInviteTeam?.uid) {
      await teamsStore.actions.joinTeamByInvite(this.inviteToken)
      this.removeCookieInvitation()
    }

    if (!this.isInviteAccepted && this.invitations.length > 1) {
      loginStore.actions.pushAcceptedInviteUid(uid)
      return
    }

    uiSettingsStore.actions.initTeam({ teamId: uid })
    await teamsStore.actions.initTeam(uid)
    this.$router.push({ name: 'Main' })
  }

  /**
   * If cookie invite - remove cookie and end
   * Else dispatch store action - based on deleting user self from that team by uid
   * @see declineTeamInvitation
   */
  async declineInvite () {
    window.goal('onboardingAction', { onboarding: 'Приглашения — «Отклонить»' })
    if (this.team.uid === this.cookieInviteTeam?.uid) {
      this.removeCookieInvitation()
      if (this.current === this.invitations.length) this.current--
      return
    }

    try {
      loginStore.actions.declineTeamInvitation({
        jid: this.team.me?.jid ?? '',
        uid: this.team.uid,
      })
      if (this.current === this.invitations.length) this.current--
    } catch (e) {
      loginLogger.error('Can`t decline the invite', e)
    }
  }

  /**
   * Remove cookie and meta data from component and document
   */
  removeCookieInvitation () {
    this.$q.cookies.remove('invitation_token')
    this.cookieInviteTeam = null
    this.inviteToken = ''
  }

  /**
   * Push to TeamsView - main teams managment page
   */
  onClickBackArrow () {
    window.goal('onboardingAction', { onboarding: 'Приглашения — «Назад»' })
    this.$router.push({ name: 'Teams' })
  }
}
